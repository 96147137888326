<template>
  <div class="app-container">
    <el-card class="filter-container" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>销量查询</span>
        <el-button style="float: right" @click="getList()" type="primary" size="small">
          查询结果
        </el-button>
        <el-button style="float: right; margin-right: 15px" @click="handleResetSearch()" size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="Start Date:">
            <el-date-picker v-model="listQuery.startDate" type="datetime" placeholder="Start Date (Inclusive)"
              value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="End Date:">
            <el-date-picker v-model="listQuery.endDate" type="datetime" placeholder="End Date (Exclusive)"
              value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="table-container">
      <el-table ref="orderTable" :data="list" style="width: 100%"
        v-loading="listLoading" border>
        <el-table-column label="Product Name" align="center">
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="Merchant Name" align="center">
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <el-table-column label="Sold Quantity" align="center">
          <template slot-scope="scope">{{ scope.row.quantity }}</template>
        </el-table-column>
        <el-table-column label="Amount" align="center">
          <template slot-scope="scope">{{ scope.row.amount }}</template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { fetchStats } from '@/api/order';

const defaultListQuery = {
  startDate: `${new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]} 00:00:00`,
  endDate: `${new Date().toISOString().split('T')[0]} 23:59:59`,
};
export default {
  name: 'home',
  data() {
    return {
      list: [],
      listLoading: false,
      listQuery: { ...defaultListQuery },
    };
  },
  methods: {
    getList() {
      this.listLoading = true;
      fetchStats(this.listQuery).then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    handleResetSearch() {
      this.listQuery = { ...defaultListQuery };
    },
  },
};
</script>
